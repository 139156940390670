import './slider.scss';

import Swiper from 'swiper';
import { A11y, Navigation, Pagination, Keyboard } from 'swiper/modules';

class Slider {
    constructor ($element, options) {
        this.$slider = $element;
        this.$slides = this.$slider.querySelectorAll('.swiper-slide');
        this.slider = null;
        this.sliderActive = false;
    }

    initialize () {
        if (this.$slides.length > 1) {
            this.initSlider();

            this.$slider.classList.add('slides__items--length-' + this.$slides.length);
        }
    }

    initSlider () {
        this.sliderActive = true;
        const $progressbar = this.$slider.querySelector('.swiper-pagination-progressbar-fill');

        const updateProgressbar = (length, index) => {
            const progress = 100 / length * (index + 1);
            if ($progressbar) {
                $progressbar.style.width = progress + '%';
            }
        };

        const $prevButton = this.$slider.querySelector('.swiper-button-prev');
        const $nextButton = this.$slider.querySelector('.swiper-button-next');
        const $fraction = this.$slider.querySelector('.swiper-pagination-fraction');

        this.slider = new Swiper(this.$slider, {
            modules: [A11y, Navigation, Pagination, Keyboard],
            slidesPerView: 'auto',
            lazyPreloadPrevNext: true,
            speed: 500,
            pagination: {
                el: $fraction,
                type: 'fraction',
                clickable: true,
                formatFractionCurrent: (number) => {
                    return ('0' + number).slice(-2);
                },
                formatFractionTotal: (number) => {
                    return ('0' + number).slice(-2);
                }
            },
            navigation: {
                nextEl: $nextButton,
                prevEl: $prevButton
            },
            keyboard: true,
            watchSlidesProgress: true,
            on: {
                init: (swiper) => {
                    updateProgressbar(swiper.snapGrid.length, swiper.snapIndex);
                },
                slideChangeTransitionStart: (swiper) => {
                    updateProgressbar(swiper.snapGrid.length, swiper.snapIndex);
                },
                fromEdge: (swiper) => {
                    updateProgressbar(swiper.snapGrid.length, swiper.activeIndex);
                },
                reachEnd: (swiper) => {
                    updateProgressbar(swiper.snapGrid.length, swiper.snapGrid.length - 1);
                },
                resize: (swiper) => {
                    updateProgressbar(swiper.snapGrid.length, swiper.snapIndex);
                }
            }
        });
    }

    destroySlider () {
        if (this.slider !== null) {
            this.slider.destroy(true, true);
            this.slider = null;
            this.sliderActive = false;
        }
    }
}

export { Slider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$slider = $context.querySelectorAll('[data-slider="root"]');

        for (let i = 0; i < $$slider.length; i++) {
            const slider = new Slider($$slider[i]);
            slider.initialize();
        }
    }
});
